
import { metaDataMixin } from '~/mixins'

const PLAYLIST_ID = '09f0579f-461a-43d6-bb41-2b2910d1b67e'

export default {
  mixins: [
    metaDataMixin,
  ],
  async asyncData ({ $api, $config }) {
    const [
      { data: { items: featuredItems = [] } = {} },
      { data: { items: popularItems = [] } = {} },
      { data: { items: calltoactionItems = [] } = {} },
      { data: { item: playlist } = {} },
    ] = await Promise.all([
      // Get featured articles (Uitgelicht)
      $api.articles.getAll({
        channel: 'featured',
        domain: $config.preprEnvironmentDomain,
        limit: 1,
      }),
      // Get popular articles (Populair)
      $api.recommendations.getPopular(
      {
        domain: $config.preprEnvironmentDomain,
        resource: 'articles',
        limit: 3,
      }),
      // Get call to actions
      $api.calltoactions.getAll({
        channel: 'featured',
        domain: $config.preprEnvironmentDomain,
        limit: 3,
      }),
      // Get video playlist
      $api.playlists.getOne(PLAYLIST_ID),
    ])

    return {
      calltoactions: calltoactionItems,
      articles: [...featuredItems], // clone items
      articlesFeatured: featuredItems,
      articlesPopular: popularItems,
      playlist: playlist,
    }
  },
  data () {
    return {
      activeTabsList: 0,
      articles: [],
      articlesFeatured: [],
      articlesPopular: [],
      hasMore: true,
      hero: 'https://39mzhy0jsbvz.b-cdn.net/{format}/s3-beam-eo/02ec024a-dd43-4dad-bc9f-aedb05aef067.jpg',
      playlist: null,
      calltoactions: [],
    }
  },
  async fetch () {
    const showAtLeast = 20
    const limit = this.articles.length < showAtLeast ? showAtLeast - this.articles.length : 12
    const offset = this.articles.length - this.articlesFeatured.length
    const { data: { items: articles = [], meta: { total = 0 } } } = await this.$api.articles.getAll({
      domain: this.$config.preprEnvironmentDomain,
      offset,
      limit,
    })

    this.articles.push(...articles)
    this.hasMore = total > this.articles.length
  },
  computed: {
    socialCookiesAllowed () {
      return this.$store.state.cookieConsent.social
    },
  },
}
